import { IError } from '../Type';
import { GraphQLFormattedError } from 'graphql';

export function handleError(
  errors: ReadonlyArray<GraphQLFormattedError> | undefined,
): IError | undefined {
  if (errors && errors?.length > 0) {
    return {
      code: 404,
      message: errors[0].message,
    };
  }

  return undefined;
}

export function handleErrorException(errors: Error): IError | undefined {
  if (errors) {
    return {
      code: 500,
      message: errors.message,
    };
  }

  return undefined;
}
